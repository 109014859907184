import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Title } from '../../../../components';
import {
  BulkUpdateMembersResponse,
  bulkUpdateMembers
} from '../../../../services/admin/members';
import { SetState } from '../../../../types/core';
import { Document } from '../../../customer/account/consultations/booking/DocumentDropzone';
import { Paragraph } from '../../../shared/body';
import { Button } from '../../../shared/buttons';
import { Toast } from '../../../shared/toasts';
import ImportReportLine from './ImportReportLine';
import { ImportSteps } from './steps';

const ImportRecapStep = ({
  bulkUpdateMembersResponse,
  file,
  setStep,
  onCloseClicked
}: {
  file: Document;
  bulkUpdateMembersResponse: BulkUpdateMembersResponse;
  setStep: SetState<ImportSteps>;
  onCloseClicked: () => void;
}) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation('customer');
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      bulkUpdateMembers({
        customerMembersFile: file!.file,
        dryRun: false
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer-members'] });
      setStep('import-success');
    },
    onError: () => {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('admin.collaborators.form.add.error.generic')}
        />,
        { position: 'top-right', duration: 3000 }
      );
    }
  });
  return (
    <>
      <Title
        className="!font-bold"
        variant="h3"
        text="Ajouter des collaborateurs - Prévisualisation des changements"
      />

      <div className="flex flex-col gap-2 my-5">
        <ImportReportLine
          title={t('admin.collaborators.form.bulk.step.recap.added')}
          result={bulkUpdateMembersResponse.created_count}
        />
        <ImportReportLine
          title={t('admin.collaborators.form.bulk.step.recap.archived')}
          result={
            bulkUpdateMembersResponse.deactivated_count +
            bulkUpdateMembersResponse.deleted_count
          }
        />
        <Paragraph className="text-sm text-gray-550 my-4">
          {t('admin.collaborators.form.bulk.step.recap.description')}
        </Paragraph>
      </div>
      <div className="flex gap-4 justify-end">
        <Button
          onClick={onCloseClicked}
          variant="secondary"
          size="medium"
          label={t('admin.collaborators.form.bulk.step.recap.cancelImport')}
        />
        <Button
          onClick={mutate}
          isLoading={isPending}
          variant="tertiary"
          size="medium"
          label={t('admin.collaborators.form.bulk.step.recap.validate')}
          customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
        />
      </div>
    </>
  );
};

export default ImportRecapStep;
