import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

export interface LayoutModalProps {
  isModalOpen: boolean;
  handleModalClose: () => void;
  persisting?: boolean;
  withDefaultPadding?: boolean;
  children: React.ReactNode;
  isClosable?: boolean;
}

export default function LayoutModal({
  isModalOpen,
  handleModalClose,
  children,
  withDefaultPadding = true,
  persisting = false,
  isClosable = true
}: LayoutModalProps) {
  return (
    <Dialog
      open={isModalOpen}
      onClose={persisting ? () => {} : handleModalClose}
      className="relative z-10 focus:outline-none"
    >
      <div className="fixed inset-0 z-15 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogBackdrop className="fixed inset-0 bg-black/30" />
          <DialogPanel
            className={classNames(
              'relative inline-block transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg align-middle',
              withDefaultPadding && 'px-4 pt-5 pb-4 sm:p-6'
            )}
          >
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              {isClosable && (
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => handleModalClose()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              )}
            </div>
            <div>{children}</div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
