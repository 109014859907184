import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_MEMBERS_PAGE_SIZE, getMembers } from 'services/admin/members';

import { TextInputGroup } from 'features/shared/forms';

import { Toast } from '../../shared/toasts';
import AddDialogButton from './AddDialogButton';
import BulkAddDialogButton from './BulkAddDialogButton';
import BulkExportButton from './BulkExportButton';
import BulkSendOnboardingEmailDialogButton from './BulkSendOnboardingEmailDialogButton';
import CollaboratorTable from './CollaboratorsTable';

const CollaboratorsList = () => {
  const { t } = useTranslation('customer');
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    register,
    formState: { errors },
    watch
  } = useForm<{
    search: string;
  }>({
    defaultValues: { search: searchParams.get('search') || '' }
  });

  const pageIndex = Number(searchParams.get('page')) || 1;

  const search = watch('search');

  useEffect(() => {
    setSearchParams({
      search: search || '',
      page: '1'
    });
  }, [search]);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['customer-members', watch('search'), pageIndex],
    queryFn: () =>
      getMembers(watch('search'), pageIndex, DEFAULT_MEMBERS_PAGE_SIZE)
  });

  useEffect(() => {
    if (isError) {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('admin.administrators.error')}
        />,
        { position: 'top-right', duration: 3000 }
      );
    }
  }, [isError, t]);

  return (
    <>
      <div className="flex self-end my-4 gap-4 flex-wrap items-stretch">
        <div className="mt-1 w-full sm:flex-1 xl:flex-initial xl:w-[unset]">
          <AddDialogButton />
        </div>
        <div className="mt-1 w-full sm:flex-1 xl:flex-initial xl:w-[unset]">
          <BulkAddDialogButton />
        </div>
        <div className="mt-1 w-full sm:flex-1 xl:flex-initial xl:w-[unset]">
          <BulkExportButton />
        </div>
        <div className="mt-1 w-full sm:flex-1 xl:flex-initial xl:w-[unset]">
          <BulkSendOnboardingEmailDialogButton />
        </div>
      </div>
      <TextInputGroup
        type="string"
        label=""
        placeholder={t('admin.collaborators.search')}
        name="search"
        fullWidth
        register={register}
        error={errors.search}
      />
      <CollaboratorTable
        members={data}
        isLoading={isLoading}
        onPageChange={(page) => {
          setSearchParams({
            search: search || '',
            page: String(page.selected + 1)
          });
        }}
        pageIndex={pageIndex}
      />
    </>
  );
};

export default CollaboratorsList;
