import { useTranslation } from 'react-i18next';

import { Title } from '../../../../components';
import { Paragraph } from '../../../shared/body';
import { Button } from '../../../shared/buttons';
import BulkExportButton from '../BulkExportButton';
import { ImportSteps } from './steps';

const ExtractDownloadStep = ({
  setStep
}: {
  setStep: (step: ImportSteps) => void;
}) => {
  const { t } = useTranslation('customer');

  return (
    <>
      <Title
        className="!font-bold"
        variant="h3"
        text={t('admin.collaborators.form.bulk.step.extractDownload.title')}
      />
      <div className="my-8">
        <Paragraph size="sm">
          {t('admin.collaborators.form.bulk.step.extractDownload.description1')}
        </Paragraph>
        <Paragraph size="sm" className="text-gray-700 mt-4">
          {t('admin.collaborators.form.bulk.step.extractDownload.description2')}
        </Paragraph>
      </div>
      <div className="flex gap-4 justify-end mt-2">
        <BulkExportButton />
        <Button
          onClick={() => setStep('file-select')}
          variant="tertiary"
          size="medium"
          label={t('admin.collaborators.form.bulk.button.next')}
        />
      </div>
    </>
  );
};

export default ExtractDownloadStep;
