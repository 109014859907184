/* eslint-disable react/no-unstable-nested-components */
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import ArrowButtonPagination from '../../../components/pagination/ArrowButtonPagination';
import BreakPagePagination from '../../../components/pagination/BreakPagePagination';
import CollaboratorListSkeleton from '../../../components/skeleton/CollaboratorListSkeleton';
import {
  DEFAULT_MEMBERS_PAGE_SIZE,
  Member,
  MemberResponse
} from '../../../services/admin/members';
import { formatDateToIntl } from '../../../utils/date';
import { Paragraph } from '../../shared/body';
import DeleteDialogButton from './DeleteDialogButton';
import SendOnboardingEmailDialogButton from './SendOnboardingEmailDialogButton';
import UpdateDialogButton from './UpdateDialogButton';

const RESPONSIVE_HIDDEN_COLUMNS = ['addedAt', 'email'];

const CollaboratorFullNameCell = ({ member }: { member: Member }) => (
  <div>
    <span>{`${member.full_name}`}</span>
    <span className="text-gray-550 block md:hidden">{`${member.email}`}</span>
  </div>
);

const CollaboratorTypeCell = ({ member }: { member: Member }) => {
  const { t } = useTranslation('customer');
  if (member.is_cse_member) {
    return (
      <div className="flex w-fit p-1.5 bg-blue-100 uppercase rounded-md text-xs text-blue-900">
        {t('admin.collaborators.table.row.role.cse')}
      </div>
    );
  }
  return (
    <div className="flex w-fit p-1.5 bg-gray-300 uppercase rounded-md text-xs">
      {t('admin.collaborators.table.row.role.employee')}
    </div>
  );
};

const CollaboratorActions = ({ member }: { member: Member }) => (
  <div className="flex gap-2">
    <UpdateDialogButton member={member} />
    <SendOnboardingEmailDialogButton member={member} />
    <DeleteDialogButton member={member} />
  </div>
);

const CollaboratorTable = ({
  members,
  isLoading,
  onPageChange,
  pageIndex
}: {
  members?: MemberResponse;
  isLoading: boolean;
  onPageChange: (page: { selected: number }) => void;
  pageIndex: number;
}) => {
  const { t } = useTranslation('customer');
  const columns = useMemo<ColumnDef<Member>[]>(
    () => [
      {
        accessorKey: 'fullName',
        cell: ({ row }) => <CollaboratorFullNameCell member={row.original} />,
        header: () => t('admin.collaborators.table.header.fullName')
      },
      {
        accessorKey: 'email',
        cell: ({ getValue }) => getValue(),
        header: () => t('admin.collaborators.table.header.email')
      },
      {
        accessorKey: 'type',
        cell: ({ row }) => <CollaboratorTypeCell member={row.original} />,
        header: () => t('admin.collaborators.table.header.role')
      },
      {
        accessorKey: 'addedAt',
        cell: ({ row }) =>
          formatDateToIntl(new Date(row.original.added_at), 'long'),
        header: () => t('admin.collaborators.table.header.addedAt')
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: ({ row }) => <CollaboratorActions member={row.original} />
      }
    ],
    [t]
  );

  const table = useReactTable({
    data: members?.items ?? [],
    columns,
    pageCount: (members?.total || 0) / DEFAULT_MEMBERS_PAGE_SIZE,
    enableRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    getRowId: (row) => `${row.id}`
  });

  if (isLoading) return <CollaboratorListSkeleton />;
  if (table.getRowModel().rows.length === 0)
    return (
      <Paragraph className="w-full flex justify-center items-center p-8">
        {t('admin.collaborators.table.noResult')}
      </Paragraph>
    );
  return (
    <>
      <table className="w-full table-auto">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={classNames(
                    'text-left text-gray-800 font-normal py-3 px-4',
                    {
                      'hidden md:table-cell':
                        RESPONSIVE_HIDDEN_COLUMNS.includes(header.id)
                    }
                  )}
                >
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="font-medium rounded-md hover:bg-gray-200 border-y-2 border-gray-300"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={classNames('py-3 px-4', {
                    'hidden md:table-cell': RESPONSIVE_HIDDEN_COLUMNS.includes(
                      cell.column.id
                    )
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={classNames('flex w-full justify-center mt-6')}>
        <div className="flex justify-center items-center my-4">
          <ReactPaginate
            onPageChange={onPageChange}
            initialPage={pageIndex - 1}
            breakLabel={<BreakPagePagination />}
            nextLabel={<ArrowButtonPagination direction="next" />}
            containerClassName="flex justify-center items-center last:mr-0 first:ml-0 transition-all max-w-full gap-2"
            pageClassName="flex justify-center items-center px-1.5 cursor-pointer font-semibold"
            activeClassName="rounded-md text-white bg-purple-800 font-semibold"
            pageLinkClassName="p-1.5"
            pageRangeDisplayed={2}
            pageCount={Math.ceil(table.getPageCount())}
            previousLabel={<ArrowButtonPagination />}
          />
        </div>
      </div>
    </>
  );
};

export default CollaboratorTable;
