import { UploadIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BulkUpdateMembersResponse } from 'services/admin/members';

import { LayoutModal } from 'features/shared/layout';

import { Document } from '../../customer/account/consultations/booking/DocumentDropzone';
import { Button } from '../../shared/buttons';
import { SendOnboardingEmailContent } from './BulkSendOnboardingEmailDialogButton';
import ExtractDownloadStep from './import-steps/ExtractDownloadStep';
import FileSelectStep from './import-steps/FileSelectStep';
import ImportRecapStep from './import-steps/ImportRecapStep';
import ImportSuccessStep from './import-steps/ImportSuccessStep';
import { ImportSteps } from './import-steps/steps';

const BulkAddDialogButton = () => {
  const { t } = useTranslation('customer');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState<ImportSteps>('extract-download');
  const [file, setFile] = useState<Document | undefined>();
  const [bulkUpdateMembersResponse, setBulkUpdateMembersResponse] =
    useState<BulkUpdateMembersResponse>();

  const closeModal = () => {
    setIsModalOpen(false);
    setStep('extract-download');
    setFile(undefined);
    setBulkUpdateMembersResponse(undefined);
  };
  return (
    <div>
      <Button
        variant="outline"
        size="small"
        fullWidth
        onClick={() => setIsModalOpen(true)}
        label={t('admin.collaborators.button.bulk')}
        icon={<UploadIcon className="w-5 h-5" />}
      />
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModal}>
        {step === 'extract-download' && (
          <ExtractDownloadStep setStep={setStep} />
        )}
        {step === 'file-select' && (
          <FileSelectStep
            setStep={setStep}
            file={file}
            setFile={setFile}
            setBulkUpdateMembersResponse={setBulkUpdateMembersResponse}
          />
        )}
        {step === 'import-recap' && (
          <ImportRecapStep
            setStep={setStep}
            file={file!}
            bulkUpdateMembersResponse={bulkUpdateMembersResponse!}
            onCloseClicked={closeModal}
          />
        )}
        {step === 'import-success' && (
          <ImportSuccessStep setStep={setStep} onCloseClicked={closeModal} />
        )}
        {step === 'onboarding-emails' && (
          <SendOnboardingEmailContent onCloseClicked={closeModal} />
        )}
      </LayoutModal>
    </div>
  );
};

export default BulkAddDialogButton;
