import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Member, deleteMember } from 'services/admin/members';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { CrossIcon } from 'features/shared/icons';
import { LayoutModal } from 'features/shared/layout';

const DeleteDialogButton = ({ member }: { member: Member }) => {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, isPending, isSuccess, isIdle, reset } = useMutation({
    mutationFn: () => deleteMember(member.id),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['customer-members'] })
  });

  const closeModal = () => {
    setIsModalOpen(false);
    reset();
  };

  return (
    <div>
      <Button
        variant="custom"
        size="small"
        customClassNames="!p-0 text-white"
        onClick={() => setIsModalOpen(true)}
        icon={<CrossIcon className="w-6 h-6" strokeWidth={2} stroke="red" />}
      />
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModal}>
        <Title variant="h3" text="Suppression d'un collaborateur" />
        {isSuccess && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.form.delete.success')}
            </Paragraph>
            <div className="flex justify-end">
              <Button
                variant="tertiary"
                size="medium"
                label={t('general.close')}
                onClick={closeModal}
              />
            </div>
          </div>
        )}
        {(isIdle || isPending) && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.modal.delete.text', {
                fullName: member.full_name
              })}
            </Paragraph>
            <div className="flex gap-4 justify-end">
              <Button
                onClick={closeModal}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                onClick={() => mutate()}
                isLoading={isPending}
                variant="custom"
                size="medium"
                label={t('admin.collaborators.modal.delete.submit')}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </div>
        )}
      </LayoutModal>
    </div>
  );
};

export default DeleteDialogButton;
